import { CrudModel } from "./_crud";
import SolutionModel from "./solution.model";
import InsurerPlanModel from "./insurer_plan.model";

export class SolutionInsurerPlanModel extends CrudModel {
	constructor() {
		super(
			"admin/solutionInsurerPlan",
			[],
			[
				{ fieldKey: "solution", model: SolutionModel },
				{ fieldKey: "insurerPlan", model: InsurerPlanModel },
			],
		);
	}
}

export default new SolutionInsurerPlanModel();
