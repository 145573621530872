import InsurerPlanModel from "./insurer_plan.model";
import { CrudModel } from "./_crud";
import { crudRead } from "./_request";

export class corporateClientModel extends CrudModel {
	constructor() {
		super("admin/corporateClient", [], [{ fieldKey: "insurerPlans", model: InsurerPlanModel }]);
	}

	async read(id: string) {
		return crudRead(this.endpoint, id, {
			join: [
				{ field: "insurerPlans" },
				{ field: "insurerPlans.corporateClient" },
				{ field: "insurerPlans.servicePlans" },
			],
		});
	}
}

export default new corporateClientModel();
