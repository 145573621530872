var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full"},[(_vm.currentId !== 'novo')?_c('entity-crud',_vm._b({staticClass:"h-full",attrs:{"tableVariant":"secondary"}},'entity-crud',{
			model: _vm.model,
			title: _vm.title,
			tableColumns: _vm.tableColumns,
			formColumns: _vm.formColumns,
			kind: _vm.kind,
			defaultEntity: _vm.defaultEntity,
			withEntityHeader: _vm.withEntityHeader,
			cleanFormKeys: _vm.cleanFormKeys,
			isReadOnly: _vm.isReadOnly,
			shouldShowExportButton: _vm.shouldShowExportButton,
		},false)):_c('div',{staticClass:"h-full flex flex-col gap-8"},[_c('entity-crud',_vm._b({},'entity-crud',{
				model: _vm.model,
				title: _vm.title,
				tableColumns: _vm.tableColumns,
				formColumns: _vm.formColumns,
				onRemoveEntity: _vm.onRemoveEntity,
				shouldGoToPreviousPageAfterRemove: _vm.shouldGoToPreviousPageAfterRemove,
			},false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }