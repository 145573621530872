import { ISolution } from "@/interfaces/solution";
import SolutionCategoryModel from "./solution_category.model";
import SolutionInsurerPlanModel from "./solution_insurer_plan.model";
import SolutionNeedModel from "./solution_need.model";
import SolutionQualificationModel from "./solution_qualification.model";
import { CrudModel } from "./_crud";
import { crudRead } from "./_request";

export class SolutionModel extends CrudModel {
	constructor() {
		super(
			"admin/solution",
			[],
			[
				{ fieldKey: "category", model: SolutionCategoryModel },
				{ fieldKey: "solutionNeeds", model: SolutionNeedModel },
				{ fieldKey: "solutionQualifications", model: SolutionQualificationModel },
				{ fieldKey: "solutionInsurerPlans", model: SolutionInsurerPlanModel },
			],
		);
	}

	updateSolutionStatus(id: string, solution: Pick<ISolution, "status">) {
		return this.patch(null, `${id}/validate`, solution);
	}

	override async read(id: string | number, joinKey?: string | null, joinBlacklist?: string[]): Promise<any> {
		const queryParameters = {
			// join: await this.getJoinableFields(),
			// sort: { field: "availabilities.startDateTime", order: "ASC" },
		};
		return crudRead(this.endpoint, id, queryParameters);
	}
}

export default new SolutionModel();
