import NeedModel from "./need.model";
import { CrudModel, EntityDescription } from "./_crud";
import SolutionModel from "./solution.model";

export class SolutionNeedModel extends CrudModel {
	constructor() {
		super(
			"admin/solutionNeeds",
			[],
			[
				{ fieldKey: "solution", model: SolutionModel },
				{ fieldKey: "need", model: NeedModel },
			],
		);
	}
}

export default new SolutionNeedModel();
