



































import { Component, Vue, Watch } from "vue-property-decorator";

import EntityCrud from "../entity/EntityCrud.vue";
import EntityForm from "@/views/entity/EntityForm.vue";
import enterprise from "@/api/enterprise.model";
import { isValidCnpj } from "@/helpers/utils";
import solutionModel from "@/api/solution.model";

@Component({
	components: { EntityCrud, EntityForm },
})
export default class Enterprise extends Vue {
	model: any = enterprise;
	title = "Empresa";
	isReadOnly: boolean = true;
	defaultEntity = {};
	kind = "editionForm";
	withEntityHeader = true;
	cleanFormKeys = {};
	isLoading = false;
	shouldShowExportButton = false;

	shouldGoToPreviousPageAfterRemove = false;
	onRemoveEntity() {
		this.$root.$emit("refreshTable");
	}

	tableColumns = [
		{ key: "name", kind: "initialsBadges", style: "bold" },
		{ key: "cnpj", style: "bold" },
	];

	formColumns = [
		{ key: "name", isReadOnly: false, required: true },
		{ key: "cnpj", isReadOnly: false, required: false, validationField: isValidCnpj, messageError: "CNPJ inválido" },
		{
			key: "benefits",
			name: "Benefícios",
			relationKey: "solution",
			kind: "multiselect",
			formatEntity: true,
			options: [{}],
			onTransform: this.formatReadOnlySolution,
		},
	];

	benefitsField: any = {
		key: "benefits",
		name: "Benefícios",
		kind: "multiselect",
		required: false,
		relationKey: "solution",
		formatEntity: true,
		onTransform: this.formatReadOnlySolution,
		options: [{}],
	};

	userData: any = {};

	get currentId() {
		return this.$route.params.id;
	}

	@Watch("currentId")
	changeReadOnly() {
		if (!this.currentId) {
			this.isReadOnly = true;
		}
		if (this.currentId === "novo") {
			this.defaultEntity = {};
		}
	}

	creationComponent = {
		component: EntityCrud,
		props: {
			model: this.model,
			title: this.title,
			tableColumns: this.tableColumns,
			formColumns: this.formColumns,
			kind: this.kind,
			cleanFormKeys: this.cleanFormKeys,
			withEntityHeader: this.withEntityHeader,
			withBackButton: false,
			withTitle: false,
		},
	};

	onBack() {
		this.$router.back();
	}

	formatReadOnlySolution(solutions: any[]) {
		if (!solutions) {
			return;
		}
		const formatSolutions = solutions?.reduce((stringSolutions, currentSolution, indexSolution) => {
			return `${stringSolutions} ${currentSolution.solution?.name || currentSolution["solution.name"]}${
				indexSolution !== solutions.length - 1 && solutions.length > 1 ? "," : ""
			}`;
		}, "");

		return formatSolutions;
	}

	async mounted() {
		await this.getRelationFields();
	}

	async loadBenefits() {
		const solutions = await solutionModel.search({});
		if (!this.formColumns.filter((field: any) => field.key === "benefits").length) {
			this.formColumns.unshift({
				name: "Benefícios",
				key: "benefits",
				kind: "multiselect",
				relationKey: "solution",
				formatEntity: true,
				onTransform: this.formatReadOnlySolution,
				options: solutions.map((solution: any) => {
					return {
						name: solution.name,
						value: { solution: { id: solution.id, name: solution.name } },
					};
				}),
			});
		} else {
			this.formColumns.find((field: any) => field.key === "benefits")!.options = solutions.map((solution: any) => {
				return {
					name: solution.name,
					value: { solution: { id: solution.id, name: solution.name } },
				};
			});
		}
	}

	async getRelationFields(isLoading = true) {
		this.isLoading = isLoading;
		await this.loadBenefits();

		this.isLoading = false;
	}
}
