import corporateClientModel from "./corporate_client.model";
import servicePlanModel from "./service_plan.model";
import { CrudModel } from "./_crud";

export class InsurerPlanModel extends CrudModel {
	constructor() {
		super(
			"admin/insurerPlan",
			[],
			[
				{ fieldKey: "corporateClient", model: corporateClientModel },
				{ fieldKey: "servicePlans", model: servicePlanModel },
			],
		);
	}
}

export default new InsurerPlanModel();
